<!--
 * @Author: your name
 * @Date: 2022-04-27 16:49:28
 * @LastEditTime: 2022-04-28 14:34:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\serviceBox\index.vue
-->
<template>
  <div>
    <van-overlay :show="show" @click.stop :lock-scroll="false">
      <div class="center">
        <div class="title">{{title}}</div>
          <slot name="contentText">
            <div class="content-text">
    <p>一、特别提示</p>
    <p>在此特别提醒您（用户）在注册成为锐WiFi用户之前，请认真阅读本《锐WiFi用户使用服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您同意并点击确认本协议条款且完成注册程序后，才能成为锐WiFi的正式注册用户，并享受锐WiFi的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止锐WiFi用户注册程序，并可以选择不使用本应用服务。
    本协议约定锐WiFi与用户之间关于“锐WiFi”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由锐WiFi随时更新，更新后的协议条款一旦公布即代替原来的协议条款，不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用锐WiFi提供的服务，用户继续使用锐WiFi提供的服务将被视为接受修改后的协议。</p>
    <p>二、账号注册</p>
    <p>1、用户在使用本服务前需要注册一个“锐WiFi”账号。“锐WiFi”账号应当使用手机号码绑定注册，请用户使用尚未与“锐WiFi”账号绑定的手机号码，以及未被锐WiFi根据本协议封禁的手机号码注册“锐WiFi”账号。锐WiFi可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</p>
    <p>2、如果注册申请者有被锐WiFi封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，将拒绝其注册申请。</p>
    <p>3、鉴于“锐WiFi”账号的绑定注册方式，您同意锐WiFi在注册时将允许您的手机号码及手机设备识别码等信息用于注册。</p>
    <p>4、在用户注册及使用本服务时，锐WiFi需要搜集能识别用户身份的个人信息以便锐WiFi可以在必要时联系用户，或为用户提供更好的使用体验。锐WiFi搜集的信息包括但不限于用户的手机号、微信名称。</p>
    <p>三、账户安全</p>
    <p>1、用户一旦注册成功，成为锐WiFi的用户，将获得一个锐WiFi账户，并有权利使用自己的账号及验证码随时进行登录。</p>
    <p>2、用户对账户的安全负全部责任，同时对以其账户进行的所有活动和事件负全责。</p>
    <p>3、用户不得以任何形式擅自转让或授权他人使用自己的锐WiFi账户。</p>
    <p>4、用户切勿将账户信息告知他人，因信息保管不善而造成的所有损失由用户自行承担。</p>
    <p>5、如果用户泄露了账户信息，有可能导致不利的法律后果，因此不管任何原因导致用户的账户安全受到威胁，应该立即和锐WiFi客服人员取得联系，否则后果自负。</p>
    <p>四、服务内容</p>
    <p>1、锐WiFi是由河南云锐网络技术有限公司提供的WiFi设备管理平台，用户通过锐WiFi管理和维护绑定的WiFi设备。</p>
    <p>2、锐WiFi有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明，或者被锐WiFi认为不妥当的内容(包括但不限于文字、语音、图片、视频图文等)。</p>
    <p>3、所有发给用户的通告及其他消息都可通过APP或者用户所提供的联系方式发送。</p>
    <p>2、锐WiFi有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明，或者被锐WiFi认为不妥当的内容(包括但不限于文字、语音、图片、视频图文等)。</p>
    <p>3、所有发给用户的通告及其他消息都可通过APP或者用户所提供的联系方式发送。</p>
    <p>五、服务的终止</p>
    <p>1、在下列情况下,锐WiFi有权终止向用户提供服务;
    (1)在用户违反本服务协议相关规定时,锐WiFi有权终止向该用户提供服务;如该用户再一次直接或间接或以他人名义注册为用户的,一经发现,锐WiFi有权直接单方面终止向该用户提供服务;
    (2)如锐WiFi通过用户提供的信息与用户联系时,发现用户在注册时填写的联系方式已不存在或无法接通,锐WiFi以其它联系方式通知用户更改,而用户在三个工作日内仍未能提供新的联系方式,锐WiFi有权终止向该用户提供服务;
    (3)用户不得通过程序或人工方式进行刷量或作弊等非正常操作行为,若发现用户有类似行为,锐WiFi将立即终止服务,并有权扣留账户内金额；
    (4)一旦锐WiFi发现用户提供的数据或信息中含有虚假内容,锐WiFi有权随时终止向该用户提供服务;
    (5)本服务条款终止或更新时,用户明示不愿接受新的服务条款;
    (6)以及其他锐WiFi认为需要终止服务的情况。</p>
    <p>2、服务终止后,锐WiFi没有义务为用户保留原账号中或与之相关的任何信息,或转发任何未曾阅读或发送的信息给用户或第三方。</p>
    <p>3、用户理解并同意,即便在本协议终止及用户的服务被终止后,锐WiFi仍有权;
    (1)继续保存您的用户信息;
    (2)继续向用户主张在其使用平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。</p>
    <p>六、服务的变更、中断</p>
    <p>1、鉴于网络服务的特殊性，用户需同意锐WiFi会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。</p>
    <p>2、锐WiFi需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，锐WiFi无需为此承担任何责任。</p>
    <p>七、服务条款修改</p>
    <p>1、锐WiFi有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，锐WiFi将会通过适当方式向用户提示修改内容。</p>
    <p>2、如果不同意锐WiFi对本服务条款所做的修改，用户有权停止使用网络服务。</p>
    <p>3、如果用户继续使用网络服务,则视为用户接受锐WiFi对本服务条款所做的修改。</p>
    <p>八、其他</p>
    <p>1、锐WiFi郑重提醒用户注意本协议中的各项条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
    <p>2、本协议的效力、解释及纠纷的解决,适用于中华人民共和国法律。若用户和锐WiFi之间发生任何纠纷或争议，首先应友好协解决，协商不成的,用户同意将纠纷或争议提交有管辖权的人民法院管辖。</p>
    <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    <p>4、本协议最终解释权归河南云锐网络技术有限公司所有，并且保留一切解释和修改的权力。</p>
    <p>5、本协议自2022年4月24日起适用。</p>
            </div>
          </slot>
        <div class="bottom" @click="query">确定</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return{
    }
  },
  props:{
    title:{
      type:String,
      default:"服务条款"
    },
    show:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    query(){
      this.$emit("query")
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  .center{
    background-color: #f9f9f9;
    width: 70%;
    height: 60%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .title{
      width: 100%;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
    }
    .content-text{
      width: 100%;
      overflow-y: auto;
      touch-action:pan-y;
      box-sizing: border-box;
      padding: 10px;
      height: calc(100vh - 70px);
    }
    .bottom{
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      color: red;
      border-top: solid 1px #ccc;
    }
  }
}
</style>