<!--
 * @Author: your name
 * @Date: 2022-04-07 11:28:06
 * @LastEditTime: 2022-04-15 08:52:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\aboutus\index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-07 11:27:13
 * @LastEditTime: 2022-04-07 11:27:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\feedback\index.vue
-->
<template>
  <div class="aboutus">
    <div class="logobox">
      <img src="@/assets/images/abouts/logo.png" alt="" class="logo">
    </div>
    <p class="title">河南云锐网络技术有限公司</p>
    <p class="edition">当前版本:V1.01</p>
    <div class="service">客服电话<div class="right">400-960-7995</div></div>
    <feet/>
  </div>
</template>
<script>
import feet from "@/components/feet/index.vue"
export default {
  data() {
    return {

    }
  },
  components:{
    feet
  },
  mounted(){
    // JS原生设置当前背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5')
  }
}
</script>
<style lang="less" scoped>
.aboutus{
  width: 100%;
  .logobox{
    width: 80px;
    padding-top: 80px;
    margin: 0 auto;
    overflow: hidden;
    .logo{
       width: 80px;
       display: block;
       border-radius: 15px;
    }
  }
  .title{
    color: #222;
    font-size: 14px;
    text-align: center;
    line-height: 14px;
    margin:28px 0 14px 0;
    font-weight: 600;
  }
  .edition{
    color: #999;
    text-align: center;
  }
  .service{
    background-color: #fff;
    width: 92%;
    margin:  180px auto 0;
    border-radius:6px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    box-sizing: border-box;
    height: 54px;
    padding: 0 10px;
    .right{
      font-size: 20px;
      font-family: "bahn";
      font-weight: 300;
    }
  }
}
</style>