<!--
 * @Author: your name
 * @Date: 2022-04-27 16:48:29
 * @LastEditTime: 2022-04-28 10:57:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\allservice\index.vue
-->
<template>
  <div>
    <serviceBox :title="'免责声明'" :show="show" @query="query">
      <template v-slot:contentText>
        <div class="content-text">
           <p>请认真阅读本《免责声明》（以下简称“声明”），确保您充分理解本声明中各条款。请您审慎阅读并选择接受或不接受本声明。您同意并点击确认本声明且完成注册程序后，才能成为锐WiFi的正式注册用户，并享受锐WiFi的各类服务。您的注册、登录、使用等行为将视为对本声明的接受，并同意接受本声明各项条款的约束。若您不同意本声明，或对本声明中的条款存在任何疑问，请您立即停止锐WiFi用户注册程序，并可以选择不使用本应用服务。</p>
    <p>一、免责与赔偿声明</p>
    <p>1、若锐WiFi已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</p>
    <p>2、用户明确同意其使用锐WiFi所存在的风险将完全由其自己承担，因其使用锐WiFi而产生的一切后果也由其自己承担。</p>
    <p>3、用户同意保障和维护锐WiFi及其他用户的利益，由于用户在使用锐WiFi有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反任何条款而给锐WiFi及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。</p>
    <p>二、隐私声明</p>
    <p>1、适用范围:</p>
    <p>（1）在用户注册锐WiFi账户时，根据要求提供的个人注册信息；
    （2）在用户使用锐WiFi，或访问其相关网页时，锐WiFi自动接收并记录的用户浏览器上的服务器数值，包括但不限于IP地址等数据及用户要求取用的网 页记录。</p>
    <p>2、信息使用：</p>
    <p>（1）锐WiFi不会向任何人出售或出借用户的个人信息，除非事先得到用户的许可；</p>
    <p>（2）锐WiFi亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户如从事上述活动，一经发现，锐WiFi有权立即终止与该用户的服务协议，查封其账号；</p>
    <p>（3）为达到服务用户的目的，锐WiFi可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出产品和服务信息，或者与锐WiFi合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息。</p>
    <p>3、信息披露：用户的个人信息将在下述情形下部分或全部被披露：</p>
    <p>（1）经用户同意，向第三方披露；
      （2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      （3）如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
      （4）为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；
      （5）其它锐WiFi根据法律或者网站政策认为合适的披露；
      （6）用户使用锐WiFi时提供的银行账户信息，锐WiFi将严格履行相关保密约定。</p>
      <p>三、权利限制</p>
      <p> 1、禁⽌反向⼯程、反向编译和反向汇编：⽤户不得对本产品进⾏反向⼯程(Reverse Engineer)、反向编译(Decompile)或反向汇编(Disassemble)，同时不得改动编译在程序⽂件内部的任 何资源。除法律、法规明⽂规定允许上述活动外，⽤户必须遵守此协议限制。</p>
      <p> 2、组件分割：本产品是作为⼀个单⼀产品⽽被授予许可使⽤, ⽤户不得将各个部分分开⽤于任何⽬的。 </p>
      <p>3、个别授权：如需进⾏商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等，必须获得锐WiFi的授权和许可。 </p>
      <p>4、保留权利：本声明及《用户协议》中未明⽰授权的其他⼀切权利仍归锐WiFi所有。</p>
      <p>四、用户声明与保证</p>
      <p>1、用户承诺其为具有完全民事行为能力的民事主体，且具有达成交易履行其义务的能力。</p>
      <p>2、用户有义务在注册时提供自己的真实资料，并保证诸如手机号码、姓名、所在地区等内容的有效性及安全性，保证锐WiFi工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。</p>
      <p>3、用户通过使用锐WiFi的过程中所制作、上载、复制、发布、传播的任何內容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用账号或本服务所产生的内容，不得违反国家相关法律制度，包括但不限于如下原则：
      (1)违反宪法所确定的基本原则的；
      (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      (3)损害国家荣誉和利益的；
      (4)煽动民族仇恨、民族歧视，破坏民族团结的；
      (5)破坏国家宗教政策，宣扬邪教和封建迷信的；
      (6)散布谣言，扰乱社公秩序，破坏社会稳定的；
      (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      (8)侮辱或者诽谤他人，侵害他人合法权益的；
      (9)含有法律、行政法规禁止的其他内容的。</p>
      <p>4、用户不得利用“锐WiFi”账号或本服务制作、上载、复制、发布、传播下干扰“锐WiFi”的正常运营，以及侵犯其他用户或第三方合法权益的内容:(1)含有任何性或性暗示的；
      (2)含有辱骂、恐吓、威胁内容的；
      (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；
      (4)涉及他人隐私、个人信息或资料的；
      (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
      (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
      <p>五、其他</p>
      <p>1、锐WiFi有权随时修改本声明的任何内容，一旦本声明的任何内容发生变动，锐WiFi将会通过适当方式向用户提示修改内容。</p>
      <p>2、如果不同意锐WiFi对本声明所做的修改，用户有权停止使用应用服务。</p>
      <p>3、如果用户继续使用，则视为用户接受锐WiFi对本声明所做的修改</p>
      <p>4、本声明最终解释权归河南云锐网络技术有限公司所有，并且保留一切解释和修改的权力。</p>
      <p>5、本声明自2022年4月24日起适用。</p>
        </div>
      </template>
    </serviceBox>
  </div>
</template>
<script>
import serviceBox from "@/components/serviceBox/index.vue"
export default {
  data() {
    return{

    }
  },
  components:{
    serviceBox
  },
  props:{
    show:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    query(){
      this.$emit("query")
    }
  }
}
</script>
<style lang="less" scoped>
.content-text{
      overflow-x: hidden;
      overflow-y: scroll;
      overflow-y: auto;
      touch-action:pan-y;
      box-sizing: border-box;
      padding: 10px;
}
</style>